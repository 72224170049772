<template>
  <page-header-wrapper :title='this.$route.meta.title'>
    <a-row :gutter='24'>
      <a-col :sm='24' :md='6' :xl='6' :style="{ marginBottom: '24px' }">
        <chart-card :loading='loading' title='待审核' :total='ext1'>
          <a-tooltip title='待审核' slot='action'>
            <a-icon type='info-circle-o' />
          </a-tooltip>
        </chart-card>
      </a-col>
    </a-row>
    <a-form-model ref='form' :model='form' layout='inline'>
      <a-form-model-item prop='keyword'>
        <a-input v-model='form.memberMobile' style="width: 250px" placeholder="手机号" />
      </a-form-model-item>
      <a-form-model-item prop='start'>
        <a-range-picker
          v-model="dateArr"
          :format="[formatType, formatType]"
          :show-time="{
            hideDisabledOptions: true,
          }"
          :disabled-date="disabledDate"
          @change="dateChange"
        />
      </a-form-model-item>
      <a-form-model-item prop='checkStatus'>
        <a-select v-model="form.checkStatus" placeholder="状态" style="width: 250px">
          <a-select-option value="">
            全部
          </a-select-option>
          <a-select-option :value="0">
            待审核
          </a-select-option>
          <a-select-option :value="1">
            已审核
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item>
        <a-button type='primary' @click='handleSubmit'>
          查询
        </a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click='handleReset'>
<!--        <a-button @click='initialDeliver2'>-->
          重置
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <div>
      <div class='table-operator' style="margin-top: 20px">
        <a-button type='primary' icon='reload' @click='$refs.table.refresh(true)'>刷新</a-button>
      </div>

      <s-table ref='table' rowKey='id' size='default' :columns='columns' :scroll='{ x: 100}' :data='loadData'>
        <span slot='mobile' slot-scope='text, record'>
          <template>
            <a @click='handleEdit(record)'>
              {{ text }}
            </a>
          </template>
        </span>
        <span slot='auditStatus' slot-scope='text'>
          <template>
            <a-badge dot v-if='text=="1"' :status='"processing"' :text='"待审核"'></a-badge>
            <a-badge dot v-else-if='text=="2"' :status='"success"' :text='"已审核"'></a-badge>
            <a-badge dot v-else-if='text=="3"' :status='"success"' :text='"已审核"'></a-badge>
             <a-badge dot v-else-if='text=="4"' :status='"error"' :text='"已删除"'></a-badge>
          </template>
        </span>

        <span slot='id' slot-scope='text'>
           <a @click='openDetail(text)'> {{text}}</a>
        </span>

        <span slot='scene' slot-scope='text,record'>
           <template v-if="text=='1'">{{record.spreaderName }}</template>
           <template v-if="text=='2'">{{record.merchantBankAccountName }}</template>
        </span>

        <span slot='startDate' slot-scope='text'>
            {{showMonthMin(text)}}
        </span>

        <span slot='action' slot-scope='text, record'>
          <template>
            <a-space>
<!--                  <a-popconfirm title='确定审核吗？' ok-text='确认' cancel-text='取消' @confirm='handleWriteOff(record)' >-->
<!--                    <a href='#'>审核</a>-->
<!--                  </a-popconfirm>-->
                  <a-popconfirm title='确定删除吗？' ok-text='确认' cancel-text='取消' @confirm='handleDelete(record)' >
                    <a href='#'  v-if="record.checkStatus!='1'||record.checkStatus!='2'">删除</a>
                  </a-popconfirm>
            </a-space>
          </template>
        </span>
      </s-table>
    </div>

  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis, ChartCard } from '@/components'
import {
  queryApplyList, getApplyDashboard, deleteMerchantAudit,
} from '@/api/member'
import {putActivity, queryActivity499} from "@/api/activity";
import {
  checkActivity499, deleteMemberOrder1586,
  orderReturnActivity499,
  queryMemberOrder1586,
  selectTimeActivity499
} from "@/api/member-order";
import moment from "moment";

const columns = [
  {
    width: 120,
    title: '订单号',
    dataIndex: 'id',
    scopedSlots: { customRender: 'id' }
  },
  {
    width: 150,
    title: '购买人姓名',
    dataIndex: 'memberName',
  },
  {
    width: 150,
    title: '购买人手机号',
    dataIndex: 'mobile',
  },
  {
    width: 150,
    title: '推荐人姓名',
    dataIndex: 'spreaderNickname',
  },
  {
    width: 150,
    title: '下单时间',
    dataIndex: 'createTime',
  },
  {
    width: 110,
    title: '收款人',
    dataIndex: 'bankAccountName',
    scopedSlots: { customRender: 'bankAccountName' }
  },
  {
    width: 80,
    title: '状态',
    dataIndex: 'auditStatus',
    scopedSlots: { customRender: 'auditStatus' }
  },
  {
    width: 300,
    title: '操作',
    fixed: 'right',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]
const formatType = 'YYYY-MM-DD HH:mm';

const baseStart = moment().startOf('month').format(formatType);
const baseEnd = moment().startOf('day').format('YYYY-MM-DD')+ ' 23:59';

const createFormatType = 'YYYY-MM-DD HH:mm:ss';//录单时候页用到，不要乱改

export default {
  name: 'MemberApplyList',
  components: {
    STable, Ellipsis, ChartCard
  },
  data() {
    return {
      formatType,
      createFormatType,
      dateArr: [baseStart, baseEnd],
      ext1:0,//待核销数
      actList:[],//会议列表
      form: {
        keyword: "",
        start: baseStart,
        end: baseEnd,
      },
      //统计数据
      loading: true,
      dashboard: {},
      // 表头
      columns,
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        let params = Object.assign(parameter, this.form);

        return queryMemberOrder1586(params).then(datum => {
            this.ext1 = datum.ext1|0;
            return datum
          })
      },
      deliver1: {
        visible: false,
        loading: false,
        form: {},
        rules: {
          meetId: [{ required: true, message: '请选择会议场次', trigger: 'change' }],
        },
      },
      deliver2: {
        visible: false,
        loading: false,
        form: {},
        rules: {
          returnType: [{ required: true, message: '请选择退单方式', trigger: 'change' }],
        },
      },
    }
  },
  created() {
    this.getDashboard()
  },
  methods: {
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    dateChange() {
      this.form.start =  this.dateArr[0] ? moment(this.dateArr[0]).format(this.formatType) : "";
      this.form.end =  this.dateArr[1] ? moment(this.dateArr[1]).format(this.formatType) : "";
    },
    showMonthMin(_date){
      if(_date){
        const date = new Date(_date);
        const formattedDate = moment(date).format('YYYY-MM-DD HH:mm');
        return formattedDate;
      }else{
        return  "";
      }

    },
    getDashboard() {
      getApplyDashboard().then(result => {
        this.dashboard = result;
        this.loading = false;
      })
    },
    handleDelete(record) {
      deleteMemberOrder1586({ id: record.id }).then(result => {
        this.$message.success('操作成功')
        this.$refs.table.refresh(true)
      })
    },
    handleReset() {
      this.dateArr = [baseStart, baseEnd];
      this.form.start = baseStart;
      this.form.end = baseEnd;
      this.$refs.form.resetFields()
      this.$refs.table.refresh(true)
    },
    handleSubmit() {
      this.$refs.table.refresh(true)
    },
    handleEdit(record) {
      this.$router.push({ path: '/member/apply/edit', query: { id: record.id } })
    },
    initialDeliver1(record) {
      let params ={pageSize: 99};
      queryActivity499(params).then(result => {
        debugger
        this.actList = result.records;
        this.deliver1.form.orderId = record.id;
        this.deliver1.loading = false
        this.deliver1.visible = true
      })
    },

    initialDeliver2(record) {
      this.deliver2.form.orderId = record.id;
      this.deliver2.loading = false
      this.deliver2.visible = true
    },

    //打开详情页
    openDetail(id){
      this.$router.push({ path: '/order/member/detail', query: { id: id } })
    }

  }
}
</script>
